<template>
  <b-container>
    <b-row>
      <b-col cols="12" class="mt-4">
        <b-card class="mb-2" bg-variant="dark" text-variant="white">
          <h1 class="text-center">Add Collection</h1>
          <b-container class="d-flex justify-content-center">
            <b-form @submit.prevent style="min-width: 250px; width: 800px">
              <b-row>
                <b-col cols="12" md="6" class="mb-3">
                  <label for="client-name-input" class="mb-1"
                    >Client Name</label
                  >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="person"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="client-name-input"
                      type="text"
                      placeholder="Enter name..."
                      v-model="addCollectionData.client"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.client"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="client-image-input" class="mb-1"
                    >Client Logo</label
                  >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="images"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="client-image-input"
                      type="text"
                      placeholder="Enter image url..."
                      v-model="addCollectionData.clientLogo"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.clientLogo"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="numbers-addresses-input" class="mb-1"
                    >Available Addresses</label
                  >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Number of address to make available at a time when queueing"
                        ><b-icon icon="unlock"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="numbers-addresses-input"
                      type="text"
                      placeholder="Number of addresses for purchase at a time"
                      v-model="addCollectionData.availableCount"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.availableCount"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="token-limit-input" class="mb-1"
                    >Token Limit</label
                  >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="How many tokens to be minted"
                        ><b-icon icon="file-lock"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="token-limit-input"
                      type="text"
                      placeholder="Number of tokens to be sold"
                      v-model="addCollectionData.limit"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div v-for="(error, index) of errorObj.limit" :key="index">
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="start-time-input" class="mb-1">Start Time</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="clock"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="start-time-input"
                      type="datetime-local"
                      v-model="addCollectionData.startDate"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <!-- <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.startDate"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div> -->
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="whitelistDate-input" class="mb-1">whitelistDate</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="clock"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="whitelistDate-input"
                      type="datetime-local"
                      v-model="addCollectionData.whitelistDate"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <!-- <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.startDate"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div> -->
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="policyLockDate-input" class="mb-1">policyLockDate</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="clock"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="policyLockDate-input"
                      type="datetime-local"
                      v-model="addCollectionData.policyLockDate"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <!-- <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.startDate"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div> -->
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="token-p-sale-input" class="mb-1"
                    >Number of Tokens per Sale</label
                  >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="credit-card"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="token-p-sale-input"
                      type="number"
                      value="1"
                      v-model="addCollectionData.baseMintQty"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.baseMintQty"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="title-input" class="mb-1">Title</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="type-h1"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="title-input"
                      type="text"
                      placeholder="Enter title..."
                      v-model="addCollectionData.title"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div v-for="(error, index) of errorObj.title" :key="index">
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="type-input" class="mb-1">Type</label>
                  <b-input-group>
                    <b-form-select
                      id="type-input"
                      :options="type"
                      class="w-100"
                      style="height: 38px; border-radius: 5px"
                      v-model="addCollectionData.type"
                      required
                    ></b-form-select>
                  </b-input-group>
                  <div v-if="errors">
                    <div v-for="(error, index) of errorObj.type" :key="index">
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" class="mb-3">
                  <label for="description-input" class="mb-1"
                    >Description</label
                  >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="text-left"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-textarea
                      id="description-input"
                      placeholder="Enter description..."
                      rows="3"
                      max-rows="6"
                      v-model="addCollectionData.description"
                      required
                    ></b-form-textarea>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.description"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="website-input" class="mb-1">Website</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="globe"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="website-input"
                      type="text"
                      placeholder="Enter website link..."
                      v-model="addCollectionData.website"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.website"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="twitter-input" class="mb-1">Twitter</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="twitter"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="twitter-input"
                      type="text"
                      placeholder="Enter twitter link..."
                      v-model="addCollectionData.twitter"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.twitter"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="discord-input" class="mb-1">Discord</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="discord"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="discord-input"
                      type="text"
                      placeholder="Enter discord link..."
                      v-model="addCollectionData.discord"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.discord"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="image-input" class="mb-1">Image</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Info..."
                        ><b-icon icon="images"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="image-input"
                      type="text"
                      placeholder="Enter image url..."
                      v-model="addCollectionData.image"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div v-for="(error, index) of errorObj.image" :key="index">
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" class="mb-3">
                  <label for="profitAddr-input" class="mb-1"
                    >Profit Address</label
                  >
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Cardano address of the client's profit"
                        ><b-icon icon="person"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="profitAddr-input"
                      type="text"
                      placeholder="Enter profit address..."
                      v-model="addCollectionData.profitAddr"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.profitAddr"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="serviceAmt-input" class="mb-1">Service Fee</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Service fee in lovelace (minumum 1000000)"
                        ><b-icon icon="graph-down"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="serviceAmt-input"
                      type="text"
                      placeholder="Enter service fee"
                      v-model="addCollectionData.serviceAmt"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div
                      v-for="(error, index) of errorObj.serviceAmt"
                      :key="index"
                    >
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>

                <b-col cols="12" md="6" class="mb-3">
                  <label for="price-input" class="mb-1">Sale Price</label>
                  <b-input-group>
                    <template #prepend>
                      <b-input-group-text
                        style="height: 100%; border-radius: 5px 0 0 5px"
                        v-b-tooltip.hover
                        title="Total sale price in lovelace (minimum 1000000)"
                        ><b-icon icon="currency-dollar"></b-icon
                      ></b-input-group-text>
                    </template>
                    <b-form-input
                      id="price-input"
                      type="text"
                      placeholder="Enter assets price..."
                      v-model="addCollectionData.price"
                      required
                    ></b-form-input>
                  </b-input-group>
                  <div v-if="errors">
                    <div v-for="(error, index) of errorObj.price" :key="index">
                      <span class="text-danger ps-1">{{ error }}</span>
                    </div>
                  </div>
                </b-col>
              </b-row>
              <div v-if="btnDisabled" class="text-center">
                <span>{{ docRefID }}</span>
              </div>
              <b-button
                type="submit"
                variant="success"
                class="mt-4 w-100"
                @click="submitForm()"
                :disabled="btnDisabled"
                >Submit</b-button
              >
            </b-form>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

 <script>
import { db } from "@/firebase";
import { collection, addDoc } from "firebase/firestore";
import validate from "validate.js";
import constraints from "../../../src/formConstraints.js";
import moment from 'moment';

export default {
  name: "AddCollection",
  data() {
    return {
      type: [
        { value: "mint", text: "Mint" },
        { value: "collect", text: "Collect" },
        { value: "bundle", text: "Bundle" },
      ],
      addCollectionData: {
        client: "",
        clientLogo: "",
        availableCount: 50,
        limit: 100,
        baseMintQty: 1,
        startDate: "",
        whitelistDate: "",
        policyLockDate: "",
        endDate: null,
        title: "",
        description: "",
        website: "",
        twitter: "",
        discord: "",
        image: "",
        price: 1000000,
        profitAddr: "",
        serviceAmt: 1000000,
        type: "",
        queueCount: parseInt(0),
        queuePosition: parseInt(0),
        soldCount: parseInt(0),
        failCount: parseInt(0),
      },
      docRefID: "",
      btnDisabled: false,
      errors: false,
      errorObj: null,
    };
  },
  created() {
    const isAdmin = this.$store.getters.isAdmin;
    if (!isAdmin) {
      this.$router.push({ name: "ConsoleHome" });
    }
  },
  methods: {
    async submitForm() {
      // console.log(this.addCollectionData);

      // Modify form data for database submission
      const submitData = this.addCollectionData;
      submitData.startDate = moment(submitData.startDate).toDate();
      submitData.whitelistDate = moment(submitData.whitelistDate).toDate();
      submitData.policyLockDate = moment(submitData.policyLockDate).toDate();
      submitData.availableCount = parseInt(submitData.availableCount);
      submitData.limit = parseInt(submitData.limit);
      submitData.baseMintQty = parseInt(submitData.baseMintQty);
      submitData.price = parseInt(submitData.price);
      submitData.serviceAmt = parseInt(submitData.serviceAmt);

      const validation = validate(submitData, constraints);
      // console.log(validation);

      if (validation !== undefined && !this.btnDisabled) {
        // show vallidation erros on the page
        console.log("Data is not valid !!");
        this.errors = true;
        this.errorObj = validation;
      } else {
        try {
          this.errors = false
          // Adding a new document to the database
          const docRef = await addDoc(
            collection(db, "collections"),
            submitData
          );
          // Show this docRef.Id on the page once page is subitted
          console.log("Document written with ID: ", docRef.id);
          this.docRefID = `Document written with ID: ${docRef.id}`;

          // Disable the submit button so it can only happen once
          this.btnDisabled = true;
        } catch (error) {
          console.log(error);
        }
      }

      // const querySnapshot = await getDocs(q);
    },
  },
};
</script>

<style>
</style>
