const constraints = {
    client: {
        presence: { message: "is required" },
        type: "string",
        length: { minimum: 5, maximum: 100 },
        format: {
            pattern: "^[a-z ]+$",
            message: "^You can only use lowercase letters and spaces in this field"
        }
    },
    clientLogo: {
        presence: { message: "is required" },
        type: "string",
        url: true
    },
    availableCount: {
        presence: { message: "is required" },
        // type: "number",
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
            lessThanOrEqualTo: 1000,
        },
    },
    limit: {
        presence: { message: "is required" },
        // type: "number",
        numericality: {
            onlyInteger: true,
            // greaterThan: 0,
        },
    },
    baseMintQty: {
        presence: { message: "is required" },
        // type: "number",
        numericality: {
            onlyInteger: true,
            greaterThan: 0,
        },
    },
    /* startTime: {
        datetime: true,
    }, */
    title: {
        presence: { message: "is required" },
        type: "string",
        length: { minimum: 2, maximum: 100 },
    },
    description: {
        presence: { message: "is required" },
        type: "string",
        length: { minimum: 50, maximum: 2000 },
    },
    website: {
        presence: { message: "is required" },
        type: "string",
        url: true
    },
    twitter: {
        presence: { message: "is required" },
        type: "string",
        url: true
    },
    discord: {
        presence: { message: "is required" },
        type: "string",
        url: true
    },
    image: {
        presence: { message: "is required" },
        type: "string",
        url: true
    },
    price: {
        presence: { message: "is required" },
        // type: "number",
        numericality: {
            greaterThanOrEqualTo: 1000000,
        },
    },
    profitAddr: {
        presence: { message: "is required" },
        length: { minimum: 50 },
        format: {
            pattern: "^addr[a-zA-Z0-9_]+$",
            message: "^It must start with 'addr' and cannot contain symbols or spacing"
        }
    },
    serviceAmt: {
        presence: { message: "is required" },
        // type: "number",
        numericality: {
            greaterThanOrEqualTo: 1000000,
        },
    },
    type: {
        presence: { message: "is required" },
        type: "string",
        exclusion: {
            within: [""],
            message: "^You must select a valid type"
        }
    }
}

export default constraints;